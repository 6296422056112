.bg-primary {
    font-family: Rubik, sans-serif !important;
  }
  
  .rounded-borders {
    border-radius: 51px;
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}

body {
  overflow-x: hidden !important;
}

#loader > div > img {
  transform: scale(1, 1);
animation: pulse 2s infinite;
}

@keyframes pulse {
0% {
  transform: scale(0.95, 0.95);
}

70% {
  transform: scale(1, 1);
}

100% {
  transform: scale(0.95, 0.95);
}
}